export default function inviewDetect() {

	const targets = document.querySelectorAll('.module, .home-column, .related-projects, .nav-footer-container');

	const settings = {

		rootMargin: '0px 0px 0px 0px',
		threshold: 0

	}

	const intersects = (entry) => {

		if (entry.intersectionRatio > 0) {

			entry.target.classList.add('inview');

			observer.unobserve(entry.target);

		}

	}

	const observer = new IntersectionObserver((entries) => {

		entries.forEach(intersects);

	}, settings);


	targets.forEach((target) => {

		observer.observe(target);

	});

}

window.addEventListener('DOMContentLoaded', inviewDetect, false);
