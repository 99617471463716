export default function archiveFilter() {

	const filtersContainer = document.querySelector('.filters-container');

	if (filtersContainer === null) {

		return;

	}

	const indexInnerContainer = document.querySelector('.index-inner-container');
	const filters = document.querySelectorAll('.filters-container a');
	const projectLinks = document.querySelectorAll('.home-column');
	const currentFilterTitle = document.querySelector('.current-filter-title-text');
	const totalVisible = document.querySelector('.total-visible');

	const countActiveProjects = () => {

		let count = 0;
		let activeText = '';
		let activeProjects = '';

		for (let i = 0; i < projectLinks.length; i++ ) {

			if (window.getComputedStyle(projectLinks[i]).display === 'flex') {

				count = count + 1;

			}

		}

		activeText = count > 1 ? ' Projects' : ' Project';
		activeProjects = count + activeText;

		return activeProjects;

	}

	const filterTitlesCounter = (filter) => {

		currentFilterTitle.innerText = filter.dataset.filterName;
		totalVisible.innerText = countActiveProjects();

	}

	const resetFilters = (filter) => {

		indexInnerContainer.classList.add('transitioning');

		setTimeout(() => {

			for (let i = 0; i < filters.length; i++) {

				filters[i].classList.remove('inactive-filter');
				filters[i].classList.remove('active-filter');

			}

			for (let j = 0; j < projectLinks.length; j++) {

				projectLinks[j].style.display = 'flex';

			}

			currentFilterTitle.innerText = 'All Work';
			totalVisible.innerText = countActiveProjects();

			indexInnerContainer.classList.remove('transitioning');

		}, 600);

	}

	const hideProject = (el) => {

		setTimeout(() => {

			el.style.display = 'none';

		}, 600);

	}

	const showProject = (el) => {

		setTimeout(() => {

			el.style.display = 'flex';

		}, 600);

	}

	const toggleFilters = (filter) => {

		const dataFilter = filter.dataset.filter;
		const filterClass = 'filter-' + dataFilter;

		indexInnerContainer.classList.add('transitioning');

		for (let i = 0; i < projectLinks.length; i++) {

			if (!projectLinks[i].classList.contains(filterClass)) {

				hideProject(projectLinks[i]);

			} else {

				showProject(projectLinks[i]);

			}

		}

		setTimeout(() => {

			indexInnerContainer.classList.remove('transitioning');

			filterTitlesCounter(filter);

		}, 600);

	}

	const filterClick = (e) => {

		if (!e.target.matches('.filters-container a')) {

			return;

		}

		e.preventDefault();

		for (let i = 0; i < filters.length; i++) {

			if (filters[i] === e.target && e.target.classList.contains('active-filter')) {

				resetFilters(e.target);

				return;

			}

			if (filters[i] === e.target) {

				filters[i].classList.add('active-filter');
				filters[i].classList.remove('inactive-filter');

			} else {

				filters[i].classList.add('inactive-filter');
				filters[i].classList.remove('active-filter');

			}

		}

		toggleFilters(e.target);

	}

	document.addEventListener('click', filterClick, false);

}

window.addEventListener('DOMContentLoaded', archiveFilter, false);
