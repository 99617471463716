export default function renderVideo() {

	const videoModules = document.querySelectorAll('.video-module');

	if (videoModules.length === 0) {

		return;

	}

	const renderIframeEl = (videoModule) => {

		const videoId = videoModule.dataset.video;
		const videoMuted = videoModule.dataset.mute;

		const iframe = document.createElement('iframe');

		iframe.dataset.src = 'https://player.vimeo.com/video/' + videoId + '?muted=' + videoMuted + '&autoplay=true&title=false&byline=false&loop=true&portrait=0&controls=false&dnt=false&color=000000';
		iframe.width = '100%';
		iframe.height = '100%';
		iframe.setAttribute('class', 'vimeo-iframe lazyload');
		iframe.setAttribute('frameborder', '0');
		iframe.setAttribute('webkitallowfullscreen', '');
		iframe.setAttribute('mozallowfullscreen', '');
		iframe.setAttribute('allowfulscreen', '');

		return iframe;

	}

	const appendIframe = (videoModule) => {

		const videoContainer = videoModule.querySelector('.video-container');

		const iframe = renderIframeEl(videoModule);

		videoContainer.appendChild(iframe);

	}

	videoModules.forEach((videoModule) => appendIframe(videoModule));

}

window.addEventListener('DOMContentLoaded', renderVideo, false);
