import lazySizes from 'lazysizes';
import 'lazysizes/plugins/aspectratio/ls.aspectratio.js';

document.addEventListener('lazyloaded', (e) => {

	if (e.target.matches('.hero-carousel__img') || e.target.matches('.vimeo-iframe')) {

		e.target.closest('.hero-carousel__slide').classList.add('hero-carousel__slide--lazyloaded');

	}

});

import inviewDetect from './components/inview-detect.js';
import homeHeroCarousel from './components/home-hero-carousel.js';
import renderVideo from './components/render-video.js';
import archiveFilter from './components/archive-filter.js';
